var ad_width = null;
var ad_height = null;
var ad_idzone = null;

var Ads = (function(document, window) {
    var pushedAds = window.Ads || [];
    var adblockEnabled = false;

    var createCookie = function(name, value, expires, path, domain) {
        var cookie = name + "=" + encodeURIComponent(value) + ";";

        if (expires) {
            // If it's a date
            if(expires instanceof Date) {
                // If it isn't a valid date
                if (isNaN(expires.getTime()))
                    expires = new Date();
            }
            else
                expires = new Date(new Date().getTime() + parseInt(expires) * 1000 * 60 * 60 * 24);

            cookie += "expires=" + expires.toGMTString() + ";";
        }

        if (path)
            cookie += "path=" + path + ";";
        if (domain)
            cookie += "domain=" + domain + ";";

        document.cookie = cookie;
    };

    var getCookie = function(name) {
        var regexp = new RegExp("(?:^" + name + "|;\\s*"+ name + ")=(.*?)(?:;|$)", "g");
        var result = regexp.exec(document.cookie);
        return (result === null) ? null : decodeURIComponent(result[1]);
    };

    var renderTypes = {
        empty: function() {},
        script: function(element, banner) {
            renderIframe(element, banner, '<script src="' + banner.src + '"></script>');
        },
        exoclick: function(element, banner) {
            if (typeof banner.category == "undefined") {
                banner.category = 2;
            }

            if (typeof banner.resolution == "undefined") {
                banner.resolution = '300x250';
            }

            var sub = getSub(banner);

            banner.src = 'http://syndication.exoclick.com/ads.php?type=' + banner.resolution + '&login=borjan&cat=' + banner.category + '&search=&ad_title_color=0000cc&bgcolor=FFFFFF&border=0&border_color=000000&font=&block_keywords=&ad_text_color=000000&ad_durl_color=008000&adult=0&sub='+ sub +'&text_only=0&show_thumb=&idzone=' + banner.zone + '&idsite=' + banner.site;
            renderTypes['script'](element, banner);
        },
        exoclick_new: function(element, banner) {
            var content = "";

            content += '<scri' + 'pt type="text/javascript">';
            content += 'ad_idzone = "' + banner.zone + '";';
            content += 'ad_width = "' + banner.width + '";';
            content += 'ad_height = "' + banner.height + '";';
            var sub = getSub(banner);
            if (sub !== '') {
                content += 'ad_sub = "' + sub + '";';
            }
            content += '</scri' + 'pt>';
            content += '<script src="https://ads.exoclick.com/ads.js"></script>';

            renderIframe(element, banner, content);
        },
        exosrv: function(element, banner) {
            renderTypes['exoclick'](element, banner);
        },
        exosrv_new: function(element, banner) {
            var sub = getSub(banner);

            if (typeof banner.antiblock != 'undefined' && banner.antiblock && adblockEnabled) {
                ExoDetector.domain_base = "exosrv.com";
                ExoLoader.addZone({
                    "type": "banner",
                    "width": banner.width,
                    "height": banner.height,
                    "idzone": banner.zone,
                    "sub": sub,
                    "container": element
                });
            } else {
                var content = "";

                content += '<scri' + 'pt type="text/javascript">';
                content += 'ad_idzone = "' + banner.zone + '";';
                content += 'ad_width = "' + banner.width + '";';
                content += 'ad_height = "' + banner.height + '";';

                if (sub !== '') {
                    content += 'ad_sub = "' + sub + '";';
                }

                content += '</scri' + 'pt>';
                content += '<script src="https://ads.exosrv.com/ads.js"></script>';

                renderIframe(element, banner, content);
            }
        },
        bitterstramberry: function(element, banner) {
            var content = "";
            var m3_u = 'http://dv.bitterstrawberry.com/delivery/show.php';
            var m3_r = Math.floor(Math.random()*99999999999);

            content += "<script type='text/javascript' src='"+m3_u;
            content += "?site_id=" + banner.site + "&amp;adv_id=19";
            content += '&amp;cb=' + m3_r;
            content += "&amp;loc=" + escape(window.location);
            if (doc.referrer) content += "&amp;referer=" + escape(doc.referrer);
            content += "'><\/script>";

            renderIframe(element, banner, content);
        },
        kimia: function(element, banner) {
            renderIframe(element, banner, "<script>"
                + "var kimia_ad_key = \"" + banner.key + "\";"
                + "var kimia_ad_plc = \"" + banner.place + "\";"
                + "</script>"
                + "<script type=\"text/javascript\" src=\"http://adskimia.mobi/js/kimia_ad_1.1.js\"></script>");
        },
        iframe: function(element, banner) {
            var iframe = document.createElement('iframe');

            var additionalStyles = '';
            if (typeof banner.style != 'undefined') {
                additionalStyles = banner.style;
            }

            if (typeof banner.height != 'undefined') {
                iframe.setAttribute('height', banner.height);
            }
            if (typeof banner.width != 'undefined') {
                iframe.setAttribute('width', banner.width);
            }

            if (typeof banner.class != 'undefined') {
                iframe.setAttribute('class', banner.class);
            } else {
                if (additionalStyles && !additionalStyles.match(/; */)) {
                    additionalStyles += ';';
                }
                additionalStyles += 'width: 100%';
            }

            if (iframe.classList.contains('lazy')) {
                iframe.setAttribute('data-src', banner.url);
            } else {
                iframe.setAttribute('src', banner.url);
            }

            iframe.setAttribute('scrolling', 'no');
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('allowtransparency', 'true');
            iframe.setAttribute('marginwidth', '0');
            iframe.setAttribute('marginheight', '0');
            iframe.setAttribute('style', 'display: block; background: none repeat scroll 0% 0% transparent; ' + additionalStyles);



            element.appendChild(iframe);

            if (typeof iFrameResize == 'function') {
                iFrameResize({checkOrigin: false}, iframe);
            }
        },
        img: function(element, banner) {
            var link = document.createElement('a');

            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'nofollow');
            link.setAttribute('href', banner.url);

            if (banner.linkClass) {
                link.setAttribute('class', banner.linkClass);
            } else {
                link.setAttribute('style', 'margin: 0');
            }

            var image = document.createElement('img');

            image.setAttribute('src', banner.src);

            link.appendChild(image);
            element.appendChild(link);
        }
    };

    var getSub = function(banner) {
        if (banner.sub === undefined || banner.sub === null || banner.sub == '') {
            return '';
        }

        return banner.sub;
    };
    
    var renderIframe = function(element, adInfo, adContent) {
        var iframe = document.createElement('iframe');

        iframe.setAttribute('scrolling', 'no');
        iframe.setAttribute('seamless', 'seamless');
        iframe.src = 'javascript:(function() {' +
            'document.open();document.write(\'<head><style>body {margin: 0}</style></head><body>' + adContent +
            '</body>\');document.close();})();';

        var iframeStyle = iframe.style;

        iframeStyle.height = adInfo.height + 'px';
        iframeStyle.width = adInfo.width + 'px';
        iframeStyle.border = '0 none transparent';

        if (typeof adInfo.style != 'undefined') {
            iframe.setAttribute('style', adInfo.style + iframe.getAttribute('style'));
        }

        element.appendChild(iframe);
    };
    
    var alreadySelectedImages = [];
    var select = function(choices) {
        var totalWeight = 0;
        var i;
        var selectedChoice = null;
        var index = null;

        for (i = 0; i < choices.length; i++) {
            var currentChoice = choices[i];

            if (typeof currentChoice.cookiePrefix != 'undefined') {
                if (getCookie(currentChoice.cookiePrefix + '_session')) {
                    selectedChoice = currentChoice;
                    index = i;
                    break;
                } else if (getCookie(currentChoice.cookiePrefix + '_timeout')) {
                    continue;
                }
            }

            if (typeof currentChoice.weight != 'undefined') {
                totalWeight += currentChoice.weight;
            } else {
                totalWeight++;
            }
        }

        if (selectedChoice === null) {
            var selectedWeight = Math.random() * totalWeight;

            for (i = 0; i < choices.length; i++) {
                var currentChoice = choices[i];

                if (typeof currentChoice.cookiePrefix != 'undefined'
                    && getCookie(currentChoice.cookiePrefix + '_timeout')) {
                    continue;
                }

                var weight = 1;
                if (typeof currentChoice.weight != 'undefined') {
                    weight = currentChoice.weight;
                }

                selectedWeight -= weight;

                if (selectedWeight <= 0) {
                    selectedChoice = currentChoice;
                    index = i;
                    break;
                }
            }
        }

        if (typeof currentChoice.cookiePrefix != 'undefined'
            && !(
            getCookie(currentChoice.cookiePrefix + '_session')
            && getCookie(currentChoice.cookiePrefix + '_timeout'))) {

            createCookie(currentChoice.cookiePrefix + '_session', 'show', null, '/');
            createCookie(currentChoice.cookiePrefix + '_timeout', 'hide', 1, '/');
        }

        if (typeof selectedChoice.innerChoice != 'undefined') {
            selectedChoice = selectedChoice.innerChoice;
        }

        if (selectedChoice instanceof Array) {
            selectedChoice = select(selectedChoice);
        } else {
            if (selectedChoice.method == 'img') {
                if (choices.length > 1 && $.inArray(selectedChoice.src, alreadySelectedImages) != -1) {
                    choices.splice(index, 1);
                    selectedChoice = select(choices);
                }

                alreadySelectedImages.push(selectedChoice.src);
            }
        }
        return selectedChoice;
    };

    var showAd = function (element, adInfo) {

        if (adInfo === null) {
            return '';
        }

        if (adInfo instanceof Array) {
            adInfo = select(adInfo);
        }

        renderTypes[adInfo.method](element, adInfo);
    };

    var ads = {
        push: function (config) {
            var elementId = config.elementId;
            var adInfo = config.adInfo;

            if (adInfo instanceof Array) {
                adInfo = select(adInfo);
            }

            pushedAds.push({
                'elementId': elementId,
                'adInfo': adInfo
            });
        }
    };

    var firstRun = function () {
        if (typeof ExoDetector != 'undefined') {
            ExoDetector.detectCensorship(function(block) {
                adblockEnabled = block;
                showAds();
            });
        } else {
            showAds();
        }
    };

    var showAds = function () {
        ads.push = showAd;

        for (var i = 0, end = pushedAds.length; i < end; i++) {
            var data = pushedAds[i];
            showAd(document.getElementById(data.elementId), data.adInfo);
        }

        pushedAds = [];

        if (adblockEnabled) {
            ExoLoader.serve({"script_url": '/exo-force-render.php'});
        }
    };

    switch (document.readyState) {
        case 'loading':
        case 'interactive':
            if (window.addEventListener) {
                window.addEventListener('load', function() {
                    setTimeout(firstRun, 0);
                });
            } else {
                window.attachEvent('onload', function() {
                    setTimeout(firstRun, 0);
                });
            }

            break;
        case 'complete':
        default:
            firstRun();
    }

    return ads;
})(document, window);
